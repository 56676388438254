<template>
  <div>
    <v-btn @click="onOpen" text small
      ><v-icon class="mr-1">mdi-map-marker-account</v-icon>Отправить в
      регион</v-btn
    >
    <v-dialog v-model="dialog" max-width="760">
      <div class="card">
        <div class="title">Ссылка для региона</div>
        <NmicToken />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import NmicToken from '@/components/nmic/NmicToken.vue'

export default {
  props: ['userId'],
  data() {
    return {
      dialog: false,
    }
  },
  components: { NmicToken },
  methods: {
    onOpen() {
      this.dialog = true
    },
  },
}
</script>

<style scoped>
.link {
  padding: 8px;
  font-family: monospace;
  border: 1px solid #888;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
}
.card {
  padding: 16px;
  background-color: white;
  min-height: 180px;
}
</style>
