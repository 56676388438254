<template>
  <div>
    <div class="link">{{ link }}</div>
    <div class="mb-2">
      Отправьте эту ссылку пользователям в регионы. По ссылке пользователи
      зарегистрируются и смогут отвечать на вопросы по всем заболеваниям
      (состояниям), доступным вам. Ответы пользователей из регионов мы
      записываем отдельно. На Ваши ответы они не влияют.
    </div>
    <CopyToClipboard :value="link" />
  </div>
</template>

<script>
import { REGISTRATION } from '@/store/const/path'
import CopyToClipboard from '@/components/buttons/CopyToClipboard'

export default {
  data() {
    return {
      showContent: false,
    }
  },
  components: { CopyToClipboard },
  computed: {
    link() {
      return `${window.location.origin}/${REGISTRATION}/${this.$store.getters.getNmicToken}`
    },
  },
}
</script>

<style scoped>
.link {
  padding: 8px;
  font-family: monospace;
  border: 1px solid #888;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
}
</style>
